<template>
  <el-upload
    class="avatar-uploader"
    :action="action"
    :headers="headers"
    :show-file-list="false"
    :on-success="handleAvatarSuccess"
  >
    <img v-if="imageUrl" :src="imageUrl" class="avatar">
    <i v-else class="el-icon-plus avatar-uploader-icon" />
  </el-upload>
</template>

<script>
import { getToken } from '@/utils/auth'
export default {
  name: 'PsyduckUpload',
  props: {
    type: {
      type: String,
      default: 'file'
    },
    url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      imageUrl: '',
      action: process.env.VUE_APP_BASE_API + 'file/upload/oss',
      headers: {
        token: getToken()
      }
    }
  },
  watch: {
    url(newVal) {
      this.imageUrl = newVal
    }
  },
  created() {
    this.imageUrl = this.url
  },
  methods: {
    handleAvatarSuccess(res) {
      const { data } = res
      this.imageUrl = data.url
      this.$emit('update:url', this.imageUrl)
      this.$emit('update:detail', {
        width: data.width,
        height: data.height
      })
    }
  }
}
</script>

<style>

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

</style>
