import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
// import Layout from '@/layout'

export const constantRoutes = [
  {
    path: '/map',
    component: () => import('@/views/index/construction-elements'),
    hidden: true
  },
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/index',
    component: () => import('@/views/index/index'),
    hidden: true
  },
  {
    path: '/video',
    component: () => import('@/components/Video/video-wall'),
    hidden: true
  },
  {
    path : '/magic-api',
    component: () => import('@/views/magic/magic-api')
  }
]

export const asyncRoutes = [

  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  }
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
