import request from '@/utils/request'
async function getTitle(){
  const resp = await request.get('/domains');
  let obj = resp.data.find(it => it.domain.indexOf(document.location.hostname) > -1);
  return obj&&obj.name || '长江上游朝涪河段航道整治工程智慧工地';
}
export default {
  title: getTitle(),

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: false,
  showSettings: false
};

