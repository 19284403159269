import request from '@/utils/request'

export function login(data) {
  return request({
    url: 'security/login',
    method: 'post',
    data: data
  })
}

export function phoneLogin(params) {
  return request({
    url: 'security/phoneLogin',
    method: 'post',
    params
  })
}

export function sendCode(phone) {
  return request({
    url: 'sms/send',
    method: 'get',
    params: {
      phone
    }
  })
}

export function getInfo() {
  return request({
    url: 'user/info',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: 'security/logout',
    method: 'get'
  })
}

export function fetchList(params) {
  return request({
    url: 'user/list',
    method: 'get',
    params
  })
}

export function saveData(params) {
  return request({
    url: 'user/save',
    method: 'post',
    params
  })
}

export function removeData(id) {
  return request({
    url: 'user/delete',
    method: 'post',
    params: {
      id: id
    }
  })
}

export function getUserRoles(userId) {
  return request({
    url: 'user/getUserRoles',
    method: 'get',
    params: {
      userId: userId
    }
  })
}
