import {title} from '@/settings'

let getTitle = title || '前台'

export default async function getPageTitle(pageTitle) {
  if(getTitle instanceof Promise){
    getTitle = await getTitle;
  }
  if (pageTitle) {
    return `${pageTitle} - ${getTitle}`
  }
  return `${getTitle}`
}
